@font-face {
    font-family: 'barlow-bold';
    src: local('Barlow-Bold'),
    url('./Assets/fonts/Barlow-Bold.ttf')
}

@font-face {
    font-family: 'barlow-semibold';
    src: local('Barlow-SemiBold'),
    url('./Assets/fonts/Barlow-SemiBold.ttf')
}

@font-face {
    font-family: 'barlow-regular';
    src: local('Barlow-Regular'),
    url('./Assets/fonts/Barlow-Regular.ttf')
}

@font-face { 
    font-family: 'barlow-light';
    src: local('Barlow-Light'),
    url('./Assets/fonts/Barlow-Light.ttf')
}